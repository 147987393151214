<template>
  <div class="full-screen">
    <iframe v-if="iframeSrc" :src="iframeSrc" frameborder="0"></iframe>
  </div>
</template>

<script>
import { jwtDecode } from "jwt-decode";
export default {
  data() {
    return {
      token: localStorage.getItem('userToken'),
      iframeSrc: ''
    };
  },
  beforeMount() {
    const token = localStorage.getItem('userToken');

    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        this.$router.push('/login');
      }
    } else {
      this.$router.push('/login');
    }
  },
  mounted() {
    if (this.token) {
      fetch(`https://grxryrx0b1.execute-api.us-east-2.amazonaws.com/dev/kepler-pudahuel?token=${this.token}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.iframeSrc = data.url;
      })
      .catch(error => {
        console.error('Error al obtener la URL firmada:', error);
      });
    } else {
      this.$router.push('/login');
    }
  }
};
</script>

<style>
html, body, #app, .full-screen {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.full-screen iframe {
  width: 100%;
  height: 100vh;
  border: none;
}
</style>
